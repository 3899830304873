const cw = (cw || {});

(($) => {

    /**
     * Main function
     */
    function BrickContactForm(element) {

        const self = this;

        const $element = $(element),
            $alert = $('.custom-alert', $element),
            $form = $('form', $element),
            $message = $form.find("#contact_message"),
            messagePreValue = $form.find("#contact_message").html(),
            $projectInformation = $form.find("#contact_projectInformation"),
            $anchorInner = $('.anchor-inner', $element),
            lang = $('html').attr('lang'),
            eventCategory = lang.toUpperCase().concat('-Exposé'),
            eventAction = 'sent',
            eventLabel = lang,
            recaptchaV3SiteKey = ($form.find('.recaptcha-v3-site-key').length) ? $form.find('.recaptcha-v3-site-key').val() : '',
            recaptchaV3Token = $form.find('.recaptcha-v3-token'),
            recaptchaV3Action = $form.data('recaptcha-action') ? $form.data('recaptcha-action') : 'form_builder_form';

        this.init = () => {
            self.bindEvents();

            setTimeout(function(){
                self.visibleGrecaptchaBagde();
            },5000);
        };

        this.bindEvents = () => {

            self.fillExposeRequestField();

            //self.fillMessageField();
            //$projectInformation.on("change", function() {
            //    self.fillMessageField();
            //});

            self.customValidation();

            $form.on('success.form.fv', function (e) {
                e.preventDefault();

                if(recaptchaV3SiteKey != '') {
                    self.grecaptchaExecute();
                } else {
                    self.submitForm();
                }
            });
        };

        this.fillMessageField = () => {

            let projectInformationValue = $projectInformation.val() ? "'" + $projectInformation.val() + "'" : "";
            let fix = messagePreValue.replace("%Text(project)", projectInformationValue);
            $message.html(fix);
            //$message.val(fix);
        };

        this.fillExposeRequestField = () => {

            $form.find("button").on("click", function() {
                if($(this).data("expose-request") == true) {
                    if($form.find("#contact_requestExpose").length) {
                        $form.find("#contact_requestExpose").val("1");
                    }
                }
            });
        };

        this.submitForm = () => {

            $.ajax({
                url: $form.attr('action'),
                type: 'POST',
                data: $form.serialize(),
                success: function(data) {
                    self.clearForm();
                },
                complete: function (data) {
                    const result = data.responseJSON;
                    self.updateAlert(result);
                    self.showAlert();

                    // event tracking
                    try {
                        ga('send', 'event', eventCategory, eventAction, eventLabel);
                    } catch(err) {
                        // handle error
                    }

                    if (result.successPageLink) {
                        $(location).attr("href", result.successPageLink);
                    } else {
                        if($anchorInner.length) {
                            $('html, body').animate({
                                scrollTop: $anchorInner.offset().top
                            }, 1000, () => {
                            });
                        }
                    }
                }
            });
        };

        this.updateAlert = (data) => {
            const $alertMessage = $alert.find('.alert');
            $alertMessage.html(data.message);

            if(data.status === 'ok'){
                $alertMessage.attr('class', '');
                $alertMessage.addClass('alert alert-success');
            }

            if(data.status === 'error'){
                $alertMessage.attr('class', '');
                $alertMessage.addClass('alert alert-danger');
            }

            if(data.status === 'invalid'){
                $alertMessage.attr('class', '');
                $alertMessage.addClass('alert alert-warning');
            }
        };

        this.showAlert = () => {
            $alert.removeClass('hidden');

            setTimeout(function () {
                $alert.addClass('hidden');
            }, 10000);
        };

        this.clearForm = () => {
            $form.find('.form-control').val("");
            $form.find('.form-control[type="checkbox"], .form-control[type="radio"]').prop("checked", false);

            $form.data('formValidation').resetForm();
        };

        this.customValidation = () => {
            $form
                .on('change', 'input[name="contact[contactByPhone]"]', function() {

                    let contactByPhone  = $(this).is(':checked'),
                        $contactPhone = $element.find('input[name="contact[phone]"]').eq(0);

                    if (contactByPhone) {
                        $form
                        // Add field
                            .formValidation('addField', $contactPhone, { // The options are automatically parsed from HTML attributes
                                validators: {
                                    notEmpty: {
                                    }
                                }
                            });

                        $('[data-fv-for="contact[phone]"]').removeClass("d-none");

                    } else {
                        $form
                        // Remove field
                            .formValidation('removeField', $contactPhone);

                        $('[data-fv-for="contact[phone]"]').addClass("d-none");
                    }
                })
                .on('change', 'input[name="contact[contactBySMS]"]', function() {

                    let contactBySMS  = $(this).is(':checked'),
                        $contactPhone = $element.find('input[name="contact[phone]"]').eq(0);

                    if (contactBySMS) {
                        $form
                        // Add field
                            .formValidation('addField', $contactPhone, { // The options are automatically parsed from HTML attributes
                                validators: {
                                    notEmpty: {
                                    }
                                }
                            });

                        $('[data-fv-for="contact[phone]"]').removeClass("d-none");

                    } else {
                        $form
                        // Remove field
                            .formValidation('removeField', $contactPhone);

                        $('[data-fv-for="contact[phone]"]').addClass("d-none");
                    }
                })
                .on('change', 'input[name="contact[contactByLetter]"]', function() {

                    let contactByLetter  = $(this).is(':checked'),
                        $contactStreet = $element.find('input[name="contact[street]"]').eq(0),
                        $contactCity = $element.find('input[name="contact[city]"]').eq(0);

                    if (contactByLetter) {
                        $form
                        // Add field
                            .formValidation('addField', $contactStreet, { // The options are automatically parsed from HTML attributes
                                validators: {
                                    notEmpty: {
                                    }
                                }
                            })
                            .formValidation('addField', $contactCity, { // The options are automatically parsed from HTML attributes
                                validators: {
                                    notEmpty: {
                                    }
                                }
                            });

                        $('[data-fv-for="contact[street]"]').removeClass("d-none");
                        $('[data-fv-for="contact[city]"]').removeClass("d-none");

                    } else {
                        $form
                        // Remove field
                            .formValidation('removeField', $contactStreet)
                            .formValidation('removeField', $contactCity);

                        $('[data-fv-for="contact[street]"]').addClass("d-none");
                        $('[data-fv-for="contact[city]"]').addClass("d-none");
                    }
                });
        };

        this.grecaptchaExecute = function() {

            /* grecaptcha v3: https://developers.google.com/recaptcha/intro */

            // needs for recaptcha ready
            grecaptcha.ready(function() {

                grecaptcha.execute(recaptchaV3SiteKey, {action: recaptchaV3Action}).then(function (token) {
                    // add token to form
                    if (recaptchaV3Token.length) {

                        recaptchaV3Token.val(token);

                        self.submitForm();
                    }
                });
            });
        };

        this.visibleGrecaptchaBagde = function() {

            var grecaptchaBadge = $('.grecaptcha-badge');

            if(grecaptchaBadge.length) {
                grecaptchaBadge.addClass('grecaptcha-badge--visible');
            }
        };

        self.init();
    }

    $(document).ready(() => {
        $('.area-contact-form.brick').each(function(k, v){
            const $element = $(v);
            if(!$element.data('brickContactForm')){
                const data = new BrickContactForm($element);
                $element.data('brickContactForm', data);
            }
        });
    });

})(jQuery);