const cw = (cw || {});

(($) => {

    /**
     * NewsTeaser function
     */
    function NewsTeaser(element) {

        let $newsElement = $(element);
        const $newsItems = $newsElement.find('.news-teaser__wrapper');
        const $newsItemsMobile = $newsElement.find('.news-teaser__wrapper-mobile');

        if (cw.editmode) {
           return;
        }

        $newsItems.slick(
            {
                "infinite": true,
                "dots": false,
                "arrows": true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                prevArrow: $('.news-prev'),
                nextArrow: $('.news-next'),

            }
        );


        $newsItemsMobile.slick(
            {
                "infinite": true,
                "dots": false,
                "arrows": false,
                speed: 500,
                fade: true,
                cssEase: 'linear',
            }
        );
    }

    $(document).ready(() => {

        $('section.area-newsteaser').each(function (index, element) {
            const $teaserNews = $(element);
            const data = $teaserNews.data('cw.news-teaser');
            if (!data) {
                $teaserNews.data('cw.news-teaser', new NewsTeaser($teaserNews));
            }
        });

    });

})(jQuery);