const cw = (cw || {});

(($) => {

    /**
     * PropertyPropertyExportAdmin function
     */
    function PropertyPropertyExportAdmin(element) {

        const self = this;
        let $element = $(element);
        let $finderResult = $('.project-property-export-admin__results', $element);
        let $resultLists = $('#resultList', $element);
        let $resultWrapper = $('.project-property-export-admin__results-wrapper', $element);
        let $propertySearchForm = $('#projectPropertyExportAdminForm', $element);
        let $alertSuccess = $('.alert-success', $element);
        let $alertDanger = $('.alert-danger', $element);
        let $language = $($propertySearchForm).data('language');

        this.init = () => {
            self.initHandler();
            self.initPlattformExportHandler();

            if ($($propertySearchForm).length) {
                self.initFinderForm();
            }
        };

        this.initHandler = () => {
            $('#projectPropertyExportAdmin_finderResult').find('.head span.click').on('click', function (e) {
                //console.log('works');
                $($propertySearchForm).find("input[name='orderKey']").val($(this).data('orderkey'));
                if ($(this).data('order') === 'asc') {
                    $propertySearchForm.find("input[name='order']").val('desc');
                }
                else {
                    $propertySearchForm.find("input[name='order']").val('asc');
                }

                self.refreshResults();
            });
        };

        this.initPlattformExportHandler = () => {
            $('#projectPropertyExportAdmin_finderResult').find('.btn-plattform-export').on('click', function (e) {
               // console.log('export works');

                let plattformAllowSync = $(this).data('plattform-allow-sync');
                let plattformTitle = $(this).data('plattform-title');
                let plattformForeignKey = $(this).data('plattform-foreign-key');

                let data = "plattformAllowSync=" + plattformAllowSync + "&plattformTitle=" + plattformTitle + "&plattformForeignKey=" + plattformForeignKey;

                if(plattformAllowSync === 1) {
                    self.doPlattformExport(data);
                }
            });
        };

        this.initFinderForm = () => {
            $($propertySearchForm).find('.resetFilter').on('click', function (e) {
                $(':input, select', $propertySearchForm)
                    .not(':button, :submit')
                    .val('')
                    .removeAttr('selected');

                self.refreshSelect($('select', $propertySearchForm));

                self.refreshResults();
            });

            $($propertySearchForm).find('select').change(function () {
                self.refreshResults();
            });
        };

        this.refreshResults = () => {
            let url = '/admin/ajax/project-property/export/index/' + $language;
            let request = $.ajax({
                url: url,
                method: 'GET',
                data: $propertySearchForm.serialize()
            });

            $finderResult.fadeOut(400, function () {
                $resultWrapper.find('.sk-folding-cube').fadeIn(400);

                request.done(function (content) {
                    let newUri = $propertySearchForm.data('current-page') + '?' + $propertySearchForm.serialize();

                    history.pushState(
                        {current_page: newUri, previous_page: window.location.pathname},
                        window.location.title,
                        newUri
                    );
                    $finderResult.empty();

                    $resultWrapper.find('.sk-folding-cube').fadeOut(400, function () {
                        $finderResult.html(content).fadeIn(400, function () {

                            // reinit result functions
                            self.initHandler();

                            self.initPlattformExportHandler();
                        });
                    });

                });
            });

            request.fail(function (jqXHR, textStatus) {
                console.log("Request failed: " + textStatus);
            });
        };

        this.refreshSelect = (select) => {
            select.selectpicker('refresh');
        };

        this.doPlattformExport = (data) => {
            let url = '/admin/ajax/project-property/export/service/' + $language;
            let request = $.ajax({
                url: url,
                method: 'GET',
                data: data
            });

            request.done(function (content) {
                if(content.success === true) {

                    self.useAlertSuccess(content.message);
                    self.refreshResults();
                }
                if(content.success === false) {
                    self.useAlertDanger(content.message);
                }
            });

            request.fail(function (jqXHR, textStatus) {
                console.log("Request failed: " + textStatus);
            });
        };

        this.useAlertDanger = (content) => {

            $alertSuccess.slideUp(400, function () {});
            $alertDanger.slideUp(400, function () {});

            $alertDanger.html(content).slideDown(400, function () {});

            $('html, body').animate({
                scrollTop: $alertDanger.offset().top
            }, 1000, () => {

            });

            setTimeout(function()
            {
                //$alertDanger.slideUp(400, function () {});

            }, 10000);
        };

        this.useAlertSuccess = (content) => {

            $alertSuccess.slideUp(400, function () {});
            $alertDanger.slideUp(400, function () {});

            $alertSuccess.html(content).slideDown(400, function () {});

            $('html, body').animate({
                scrollTop: $alertSuccess.offset().top
            }, 1000, () => {
            });

            setTimeout(function()
            {
                $alertSuccess.slideUp(400, function () {});

            }, 10000);
        };

      //  if (!cw.editmode) {
            self.init();
      //  }
    }

    $(document).ready(() => {

        $('section.area-project-property-export-admin').each(function (index, element) {
            const $elem = $(element);
            const data = $elem.data('cw.project-property-export-admin');
            if (!data) {
                $elem.data('cw.project-property-export-admin', new PropertyPropertyExportAdmin($elem));
            }
        });

    });

})(jQuery);