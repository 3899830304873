const cw = (cw || {});

(($) => {

    /**
     * NaturalSearch function
     */
    function NaturalSearch(element) {

        const self = this;
        let $element = $(element);
        let $language = $element.data('language');
        let $naturalSearchForm = $('#naturalSearchForm');

            this.init = () => {

            self.registerEvents();
            self.initSelect();
            self.getOptions(true);

        };

        this.registerEvents = () => {
            let indexToEnable;

            $element.find("select").on("change", function(e){
                indexToEnable = $(this).data('index') + 1;
                self.enableSelect(indexToEnable);
                self.clearPostSiblingSelects($(this).data('index'));
            });

            $element.find("select").on("change", function(e){
                self.getOptions();
            });
        };

        this.enableSelect = (indexToEnable) => {
            $element.find("select").each(function(k,v){
                if(k == indexToEnable){
                    $(v).removeAttr("disabled");
                    $(v).selectpicker("refresh");
                }
            });
        };

        this.clearPostSiblingSelects = (index) => {
            $element.find("select").each(function(k,v){
                if(k > index){
                    $(v).val($(v).find('option').eq(0).val()); // index starts at 0
                    $(v).selectpicker("refresh");
                }
            });

            self.initSelect(index + 1);
        };

        this.initSelect = (index = 0) => {
            $element.find("select").each(function(k,v){
               if(k > index){
                   $(v).attr("disabled", "disabled");
               }
            });
        };

        this.getOptions = (init = false) => {
            //console.log($naturalSearchForm.serialize());

            let url = '/' + $language + '/ajax/search/natural/select-options';
            $.ajax({
                url: url,
                method: 'GET',
                data: $naturalSearchForm.serialize(),
               // dataType: "json",
                success: function(data) {
                    let select;

                    select = document.getElementById("region");
                    self.updateOptions(select, data.regionOptions, init);

                    select = document.getElementById("who");
                    self.updateOptions(select, data.whoOptions, init);

                    select = document.getElementById("type");
                    self.updateOptions(select, data.typeOptions, init);

                    // Get the raw DOM object for the select box
                    select = document.getElementById("purpose");
                    self.updateOptions(select, data.purposeOptions, init);

                    // Get the raw DOM object for the select box
                    select = document.getElementById("lifestyle");
                    self.updateOptions(select, data.lifestyleOptions, init);

                    // Get the raw DOM object for the select box
                    select = document.getElementById("infrastructure");
                    self.updateOptions(select, data.infrastructureOptions, init);

                    if(init === true) {
                      //  self.preselectOptions();
                    }
                }
            });
        };

        this.updateOptions = (select, options, init = false) => {

            let preselectValue = $(select).val();

            // Clear the old options
            select.options.length = 1;

            // Load the new options
            if ($(select).attr('id') == "region") {
                for (name in options) {
                    if (options.hasOwnProperty(name)) {
                        select.options.add(new Option(options[name].title, options[name].id));
                    }
                }
            } else {
                for (name in options) {
                    if (options.hasOwnProperty(name)) {
                        //console.log(options[name]);
                        //console.log(name);
                        select.options.add(new Option(options[name], name));
                    }
                }
            }

            $(select).val(preselectValue);
            $(select).selectpicker('refresh');
        };

        this.preselectOptions = () => {

            let select = document.getElementById("region");
            $(select).val($(select).find('option').eq(1).val()).trigger('change'); // index starts at 0

            setTimeout(function() {
                select = document.getElementById("who");
                $(select).val($(select).find('option').eq(1).val()).trigger('change'); // index starts at 0
            }, 1000);

            setTimeout(function() {
                select = document.getElementById("type");
                $(select).val($(select).find('option').eq(1).val()).trigger('change'); // index starts at 0
            }, 2000);

            setTimeout(function() {
                select = document.getElementById("purpose");
                $(select).val($(select).find('option').eq(1).val()).trigger('change'); // index starts at 0
            }, 3000);

            setTimeout(function() {
                select = document.getElementById("lifestyle");
                $(select).val($(select).find('option').eq(1).val()).trigger('change'); // index starts at 0
            }, 4000);

            setTimeout(function() {
                select = document.getElementById("infrastructure");
                $(select).val($(select).find('option').eq(1).val()).trigger('change'); // index starts at 0
            }, 5000);
        };

        if (!cw.editmode) {
            self.init();
        }
    }

    $(document).ready(() => {

        $('.natural-search-ajax').each(function (index, element) {
            const $naturalSearch = $(element);
            const data = $naturalSearch.data('cw.naturalSearch');
            if (!data) {
                $naturalSearch.data('cw.naturalSearch', new NaturalSearch($naturalSearch));
            }
        });

    });

})(jQuery);