const cw = (cw || {});

(($) => {

    function BrickMap(element) {

        const self = this;
        let $map = $('.project-map', element);
        let $disableMap = $map.data("disable-map") === true;
        let $mapSwitcher = $('#mapSwitcher', element);
        let $elementPreviousSibling = element.prev();
        let $headerStage = $elementPreviousSibling && $elementPreviousSibling.hasClass('header-stage') ? $elementPreviousSibling : false;
        let layout = $('body').data("layout");

        this.init = () => {

            if ($mapSwitcher.length) {
                self.initMapSwitcher();
            }

            if(layout !== "fair") {
                if($disableMap) {
                    self.hideMap();
                } else {
                    self.showMap();
                }
            }
        };

        this.initMapSwitcher = () => {

            $mapSwitcher.change(function () {
                if (this.checked) {
                    self.showMap();
                }
                else {
                    self.hideMap();
                }
            });
        };

        this.showMap = () => {
            $map.slideDown(800);
            element.addClass("map-shown");

            if($headerStage) {
                $headerStage.slideUp(800);
            }
        };

        this.hideMap = () => {
            $map.slideUp(800);
            element.removeClass("map-shown");

            if($headerStage){
                $headerStage.slideDown(800);
            }
        };

        if (!cw.editmode) {
            self.init();
        }
    }

    $(document).ready(() => {
        $('.area-map.brick').each(function(k, v){
            const $element = $(v);
            if(!$element.data('brickMap')){
                const data = new BrickMap($element);
                $element.data('brickMap', data);
            }
        });
    });

})(jQuery);