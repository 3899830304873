const cw = (cw || {});

(($) => {

    /**
     * QuickSearch function
     */
    function QuickSearch(element) {

        const self = this;
        let $element = $(element);
        let $selectRegion = $(element).find('select.quick-search__region');
        let $optionLevel2Visible = false;

        this.init = () => {

            self.initOptions();

            self.registerEvents();

        };

        this.registerEvents = () => {
            /*
            $selectRegion.on("changed.bs.select",
                function() {
                    $selectRegion.find('option.level-2').slideDown();
                    $selectRegion.selectpicker('refresh');
                }
            );
            */
            $selectRegion.on("rendered.bs.select",
                function() {
                    $selectRegion.parent('.bootstrap-select').find('.bs-searchbox input').keypress(function() {
                       if($optionLevel2Visible === false) {

                           $selectRegion.find('option.level-2').slideDown();
                           $optionLevel2Visible = true;

                           setTimeout(function() {
                               $selectRegion.selectpicker('refresh');
                           }, 500);
                       }
                    });
                }
            );
        };

        this.initOptions = () => {

            $selectRegion.find('option.level-2').slideUp();

        };

        if (!cw.editmode) {
            self.init();
        }
    }

    $(document).ready(() => {

        $('.stage-snippet .quick-search').each(function (index, element) {
            const $quickSearch = $(element);
            const data = $quickSearch.data('cw.quickSearch');
            if (!data) {
                $quickSearch.data('cw.quickSearch', new QuickSearch($quickSearch));
            }
        });

    });

})(jQuery);