const cw = (cw || {});

(($) => {

    /**
     * Main function
     */
    function LogoBar($logoBar) {

        const self = this;

        $logoBar.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToScroll: 1,
            slidesToShow: 5,
            centerMode:false,
            arrows: true,
            variableWidth: true,
            prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><span class="icon icon-arrow-left_m"><i class="fas fa-chevron-left"></i></span></button>',
            nextArrow: '<button class="slick-next" aria-label="Next" type="button"><span class="icon icon-arrow-right_m"><i class="fas fa-chevron-right"></i></span></button>'
        });

    }

    $(document).ready(() => {

        $('.area-logo-bar__slider').each(function (index, element) {
            const $logoBar = $(element);
            const data = $logoBar.data('cw.logoBar');
            if (!data) {
                $logoBar.data('cw.logoBar', new LogoBar($logoBar));
            }
        });

    });

})(jQuery);