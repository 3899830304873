const cw = (cw || {});

(($) => {

    /**
     * PropertyFinder function
     */
    function PropertyFinder(element) {

        const self = this;
        let $finderResult = $('.finder__results');
        let $resultLists = $('#resultList, #similiar-objects__results');
        let $resultWrapper = $('.property-search__results');
        let $propertySearchForm = $('#finder #propertySearchForm');

        let $language = $($propertySearchForm).data('language');
        let $map = $('.project-map');

        this.init = () => {
            self.initHandler();

            self.openFirstProperty();

            if ($('#mapSwitcher').length) {
                self.initMapSwitcher();
            }
            if ($($propertySearchForm).length) {
                self.initFinderForm();
            }
        };

        this.initHandler = () => {
            $resultLists.find('.property .details').hide();

            $('#finderResult').find('.head span.click').on('click', function (e) {
               // console.log('works');
                $($propertySearchForm).find("input[name='orderKey']").val($(this).data('orderkey'));
                if ($(this).data('order') === 'asc') {
                    $propertySearchForm.find("input[name='order']").val('desc');
                }
                else {
                    $propertySearchForm.find("input[name='order']").val('asc');
                }

                self.refreshResults();
            });

            $finderResult.find('.property').each(function (index, element) {
                $(element).find('.clickable').on('click', function (e) {
                    self.closeOthers();

                    var property = $(this).parent();

                    if ($(property).hasClass('open')) {
                        $(this).next().slideUp(600, function () {
                            $(property).removeClass('open');
                        });

                        $(this).find('.icon-plus').show();
                        $(this).find('.icon-minus').hide();
                    }
                    else {
                        $(property).addClass('open');

                        $(this).next().slideDown(600);

                        $(this).find('.icon-plus').hide();
                        $(this).find('.icon-minus').show();
                    }

                    e.stopImmediatePropagation();
                });
            });
        };

        this.closeOthers = () => {
            $resultLists.find('.property.open').each(function (index, element) {
                var property = $(this);

                $(property).find('.details').slideUp(600, function () {
                    $(property).removeClass('open');
                });

                $(property).find('.icon-plus').show();
                $(property).find('.icon-minus').hide();
            });
        };

        this.openFirstProperty = () => {
            self.openProperty($($finderResult.find('.property').first()));
        };

        this.openProperty = (property) => {
            $(property).find('.clickable').trigger("click");
        };

        this.initMapSwitcher = () => {
            $('#mapSwitcher').change(function () {
                if (this.checked) {
                    $('.project-map').slideDown(800);
                }
                else {
                    $('.project-map').slideUp(800);
                }
            });
        };

        this.initFinderForm = () => {
            $($propertySearchForm).find('.resetFilter').on('click', function (e) {
                $(':input, select', $propertySearchForm)
                    .not(':button, :submit')
                    .val('')
                    .removeAttr('selected');

                self.refreshSelect($('select', $propertySearchForm));

                self.refreshResults();
            });

            $($propertySearchForm).find('select').change(function () {
                self.refreshResults();
            });
        };

        this.refreshResults = () => {
            var url = '/' + $language + '/ajax/finder/results';
            var request = $.ajax({
                url: url,
                method: 'GET',
                data: $propertySearchForm.serialize()
            });

            //console.log($propertySearchForm.serialize());

            $finderResult.fadeOut(400, function () {
                $resultWrapper.find('.sk-folding-cube').fadeIn(400);

                request.done(function (content) {
                    var newUri = $propertySearchForm.data('current-page') + '?' + $propertySearchForm.serialize();

                    history.pushState(
                        {current_page: newUri, previous_page: window.location.pathname},
                        window.location.title,
                        newUri
                    );

                    $finderResult.empty();

                    $resultWrapper.find('.sk-folding-cube').fadeOut(400, function () {
                        $finderResult.html(content).fadeIn(400, function () {
                            if ($map.length) {
                                // reinit markers on map
                                cw.finderMap.clearMarkers();
                                cw.finderMap.updateMarkers();
                            }

                            // reinit result functions
                            self.initHandler();

                            // reinit lightbox
                            $('.fancybox').fancybox();
                        });
                    });
                });
            });

            request.fail(function (jqXHR, textStatus) {
                console.log("Request failed: " + textStatus);
            });
        };

        this.refreshSelect = (select) => {
            select.selectpicker('refresh');
        };

        if (!cw.editmode) {
            self.init();
        }
    }

    cw.propertyFinder = new PropertyFinder();

})(jQuery);