const cw = (cw || {});

(($) => {

    /**
     * FooterLocationsMap function
     */
    function FooterLocationsMap(element) {

        const self = this;
        let $element = $(element);

        const $locationAddresses = $element.find('.footer-locations');

        this.init = () => {
            self.registerEvents();
        };

        this.registerEvents = function() {

            $element.find('.footer-locations__map-pin').on('click', function() {
                let locationId = $(this).data('location-id');
                $locationAddresses.find('.footer-locations__item').removeClass('active');
                $locationAddresses.find('.footer-locations__item#' + locationId).addClass('active');
                $(this).parents().find('.footer-locations__map-pin').removeClass('active');
                $(this).addClass('active');
            });
        };

        if (!cw.editmode) {
            self.init();
        }

    }

    $(document).ready(() => {

        $('footer .footer-locations-wrapper').each(function (index, element) {
            const $footerLocationsMap = $(element);
            const data = $footerLocationsMap.data('cw.footer-locations-map');
            if (!data) {
                $footerLocationsMap.data('cw.footer-locations-map', new FooterLocationsMap($footerLocationsMap));
            }
        });

    });

})(jQuery);