const cw = (cw || {});

(($) => {

    /**
     * Main function
     */
    function CwFormValidation(element) {

        const self = this;

        const $element = $(element);

        this.init = () => {
            self.initValidation();
        };

        this.initValidation = () => {

            const language = $element.data('language');

            $element.formValidation({
                framework: 'bootstrap4',
                locale: language
            });
        };

        self.init();

    }

    $(document).ready(() => {
        $('form.validation').each(function(k, v){
            const $element = $(v);
            if(!$element.data('cwFormValidation')){
                const data = new CwFormValidation($element);
                $element.data('cwFormValidation', data);
            }
        });
    });

})(jQuery);