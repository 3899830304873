const cw = (cw || {});

(($) => {

    /**
     * Stage function
     */
    function Stage(element) {

        const self = this;
        let $element = $(element);

        this.init = () => {

            self.initHandler();

            const slidesCount = $element.find('.stage-snippet__slide').length;

            $element.find('.stage-snippet__slider').slick({
                infinite: true,
                dots: (slidesCount > 1),
                arrows: false,
            });

        };

        this.initHandler = () => {

            $element.find('.stage-snippet__slide-down-button').click(() => {
                $('body,html').animate({ scrollTop: ($element.height() + $element.offset().top) }, 800);
            });

        };

        if (!cw.editmode) {
            self.init();
        }

    }

    $(document).ready(() => {

        $('section.header-stage').each(function (index, element) {
            const $stage = $(element);
            const data = $stage.data('cw.stage');
            if (!data) {
                $stage.data('cw.stage', new Stage($stage));
            }
        });

    });

})(jQuery);