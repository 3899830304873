const cw = (cw || {});

(($) => {

    /**
     * Teaser function
     */
    function Teaser(element) {

        const self = this;
        let $element = $(element);

        this.init = () => {
            const $carouselItem = $element.find('.this-is-carousel');
            const $items = $element.find('.area-teaser__items');

            if ($items.hasClass('area-teaser__items--slider')) {

                const slidesCount = $items.find('.teaser-items__row').length;

                $items.slick({
                    infinite: true,
                    dots: (slidesCount > 1),
                    arrows: true,
                    prevArrow: '<div class="prev"><img src="/static/icomoon/SVG/arrow_left_m.svg"></div>',
                    nextArrow: '<div class="next"><img src="/static/icomoon/SVG/arrow_right_m.svg"></div>',
                    customPaging : function(slider, i) {
                        return '<img src="/static/icomoon/SVG/dot_navigation_small_m.svg"/><img src="/static/icomoon/SVG/dot_navigation_big_m.svg" />';
                    },
                });

            } else if ($items.hasClass('area-teaser__items--masonry')) {
                $items.isotope({
                    itemSelector: '.teaser-item',
                    percentPosition: true,
                    masonry: {
                        columnWidth: '.teaser-item',
                    }
                });
            } else if ($items.hasClass('area-teaser__items--panorama')) {

                $carouselItem.on("touchstart", function(event) {
                    var xClick = event.originalEvent.touches[0].pageX;

                    $(this).one("touchmove", function(event) {
                        var xMove = event.originalEvent.touches[0].pageX;

                        if( Math.floor(xClick - xMove) > 5 ) {
                            $(this).carousel('next');
                        } else if ( Math.floor(xClick - xMove) < -5 ) {
                            $(this).carousel('prev');
                        }
                    });
                    
                    $carouselItem.on("touchend", function(){
                        $(this).off("touchmove");
                    });
                });
            }
        };

        if (!cw.editmode) {
            self.init();
        }

    }

    $(document).ready(() => {

        $('section.area-teaser').each(function (index, element) {
            const $teaser = $(element);
            const data = $teaser.data('cw.teaser');
            if (!data) {
                $teaser.data('cw.teaser', new Teaser($teaser));
            }
        });

    });

})(jQuery);