"use strict";

const cw = (cw || {});

(($) => {

    /**
     * Hides the header on scroll-down, shows it on scroll-up
     */
    function HeaderScrollWatcher(element) {
        let self = {};

        let $element = $(element);

        const $mobileNavigation = $('.mobile-navigation');

        self.didScroll = false;
        self.lastScrollTop = 0;
        self.delta = 4;
        self.intervalTimer = 10;
        self.navbarHeight = 118;

        self.hasScrolled = hasScrolled;
        self.debounceCheck = debounceCheck;
        self.init = init;
        self.registerEvents = registerEvents;
        self.initVars = initVars;
        self.isScrolledTop = isScrolledTop;

        if (!cw.editmode) {
            self.init();
        }

        return self;

        function init() {
            self.initVars();
            self.registerEvents();
            self.isScrolledTop();
        }

        function isScrolledTop() {
            let st = $(document).scrollTop();
            if (st === 0) {
                $element.addClass('is-top');
                $mobileNavigation.addClass('is-top');
            } else {
                $element.removeClass('is-top');
                $mobileNavigation.removeClass('is-top');
            }
        }

        function registerEvents() {
            $(window).scroll((e) => {
                self.didScroll = true;
            });
            self.interval = setInterval(debounceCheck, self.intervalTimer);
        }

        function initVars() {
            self.navbarHeight = $element.outerHeight();
        }

        function hasScrolled() {
            let st = $(document).scrollTop();
            if (st < self.navbarHeight) {
                $element.removeClass('animated');
            } else {
                $element.addClass('animated');
            }
            if (Math.abs(self.lastScrollTop - st) <= self.delta) {
                return;
            }
            // If current position > last position AND scrolled past navbar...
            if (st > self.lastScrollTop && st > self.navbarHeight) {
                // Scroll Down
                $element.removeClass('nav-down').addClass('nav-up');
            } else {
                // Scroll Up
                // If did not scroll past the document (possible on mac)...
                if (st + $(window).height() < $(document).height()) {
                    $element.removeClass('nav-up').addClass('nav-down');
                }
            }

            if (st === 0) {
                $element.addClass('is-top');
                $mobileNavigation.addClass('is-top');
            } else {
                $element.removeClass('is-top');
                $mobileNavigation.removeClass('is-top');
            }

            self.lastScrollTop = st;
        }

        function debounceCheck() {
            if (self.didScroll) {
                self.hasScrolled();
                self.didScroll = false;
            }
        }
    }

    cw.HeaderScrollWatcher = HeaderScrollWatcher;

    $(document).ready(() => {
        $('.smart-hide').each((index, element) => {
            let $elem = $(element);
            let data = $elem.data('cw.HeaderScrollWatcher');
            if (!data) {
                $elem.data('cw.HeaderScrollWatcher', new HeaderScrollWatcher($elem));
            }
        });
        $('.burger').on('click', () => {
            $(this).toggleClass('is-active');
            if (!$(this).hasClass('is-active')) {
                $(this).addClass('not-active');
            } else {
                $(this).removeClass('not-active');
            }
            $('header').toggleClass('open');
        })
    });

    //checks if the page scroll is = 0

    /*function amountscrolled(){
        var winheight = $(window).height();
        var docheight = $(document).height();
        var scrollTop = $(window).scrollTop();
        var trackLength = docheight - winheight;
        var pctScrolled = Math.floor(scrollTop/trackLength * 100); // gets percentage scrolled (ie: 80 NaN if tracklength == 0)
        console.log(pctScrolled);
        if(pctScrolled > 5){
            //$('body').removeClass('home');
            //$('#siteWrapper.top-navigation').css('padding-top', '80px')
        };
    }

    $(window).on("scroll", function(){
        amountscrolled()
    })*/

})(jQuery);