const cw = (cw || {});

(($) => {

    /**
     * Gallery function
     */
    function Gallery(element) {

        const self = this;
        let $element = $(element);

        this.init = () => {

            self.initHandler();

            const slidesCount = $element.find('.gallery__item').length;

            $element.find('.gallery__slider').slick({
                infinite: true,
                dots: (slidesCount > 1),
                arrows: true,
                autoplay: true,
                autoplaySpeed: 2000,
                prevArrow: $element.find('.slick-prev'),
                nextArrow: $element.find('.slick-next'),
                speed: 500,
                fade: true,
                swipe: true,
                cssEase: 'linear'

            });

            $('.nav-tabs .nav-link').on('shown.bs.tab', function () {
                $element.find('.gallery__slider').slick('unslick').slick({
                    infinite: true,
                    dots: (slidesCount > 1),
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    prevArrow: $element.find('.slick-prev'),
                    nextArrow: $element.find('.slick-next'),
                    speed: 500,
                    fade: true,
                    swipe: true,
                    cssEase: 'linear'

                });
            });
        };

        this.initHandler = () => {
            $element.find('.stage-snippet__slide-down-button').click(() => {
                $('body,html').animate({ scrollTop: ($element.height() + $element.offset().top) }, 800);
            });

        };

        if (!cw.editmode) {
            self.init();
        }

    }

    $(document).ready(() => {

        $('section.area-gallery').each(function (index, element) {
            const $gallery = $(element);
            const data = $gallery.data('cw.stage');
            if (!data) {
                $gallery.data('cw.stage', new Gallery($gallery));
            }
        });

    });

})(jQuery);