const cw = (cw || {});

(($) => {

    /**
     * Main function
     */
    function Main() {

        const self = this;

        this.init = () => {
            self.smoothScroll();

            if ($('body').hasClass('editmode')) {
                self.handleCardCollapseStatus();
            }
            if($('footer').prev().hasClass('brick area-section--grey-background')){
                self.removeFooterPadding();
            }

            $('.fancybox').fancybox();

            window.addEventListener('online',  self.updateIndicator());
            window.addEventListener('offline', self.updateIndicator());

            self.updateIndicator();

            self.initStickyElement();

            self.initAreaProjectPage();
        };

        this.smoothScroll = () => {
            $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('.nav-link').click((event) => {
                if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                    let target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000, () => {
                            let $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) {
                                return false;
                            } else {
                                $target.attr('tabindex', '-1');
                                $target.focus();
                            }
                        });
                    }
                }
            });
        };

        this.updateIndicator = () => {
            if(navigator.onLine) {
                $('body').addClass('online');
                $('body').removeClass('offline');
            }
            else {
                $('body').addClass('offline');
                $('body').removeClass('online');
            }
        };

        this.handleCardCollapseStatus = () => {

            $('.card-collapse-toggle').click(function() {
                const checkbox = $(this).find('.card-open-status-checkbox input[type=checkbox]');
                checkbox.prop("checked", !checkbox.prop("checked"));
            });

        };

        this.removeFooterPadding = () =>{
            $('footer').css("padding-top", 0);
        };

        this.isOnTopOnScreen = function($element) {
            let win = $(window);
            let obj = $element;
            let scrollPosition = win.scrollTop();
            let visibleArea = win.scrollTop();
            let objStartPos = obj.offset().top;

            return (visibleArea >= objStartPos ? true : false);
        };

        this.initStickyElement = () =>{
            if($(".enable-sticky").length){
                $(".enable-sticky").each(function(k,v) {

                    let sticky = $(this).offset().top;

                    $(window).scroll(function() {

                        if ( $(window).scrollTop() >= sticky ) {
                            $(v).addClass("sticky");
                        } else {
                            $(v).removeClass("sticky");
                        }
                    });
                });
            }
        };

        this.initAreaProjectPage = () =>{
            if($(".area-project-page").length){

                let $target = $(".area-project-page");
                let $navItems = $(".area-project-page #project_page_nav_tabs a");

                $navItems.on('click', function (e) {

                    $('html, body').animate({
                        scrollTop: $target.offset().top
                    }, 1000, () => {

                    });
                });
            }
        };
    }

    $(document).ready(() => {
        cw.main = new Main();
        cw.main.init();
    });

})(jQuery);