"use strict";

const cw = (cw || {});

(($) => {

    /**
     * Hides the header on scroll-down, shows it on scroll-up
     */
    function MobileNavigation() {
        const self = this;

        const $body = $('body');
        const $button = $('.mobile-menu-button');
        const $closeHelper = $('.mobile-navigation__close-helper');
        const $cross = $('.mobile-navigation__cross');
        const mql = window.matchMedia("(min-width: 992px)");
        const $childMenu = $(document).find('ul.main-navigation__item--sub-navigation');
        const $navigation = $('.mobile-navigation');
        let bodyScrollTop = 0;

        const classes = {
            mobileNavigationOpen: 'mobile-menu-open'
        };

        this.init = () => {
            self.registerEvents();
            self.toggleNavigation();
        };

        this.registerEvents = () => {
            mql.addListener(function () {
                if (mql.matches) {
                    self.closeNavigation();
                }
            });

            $button.on('click', function () {
                self.openNavigation();
            });

            $closeHelper.on('click', function () {
                self.closeNavigation();
            });

            $cross.on('click', function () {
                self.closeNavigation();
            });
        };

        this.openNavigation = () => {
            $body.addClass(classes.mobileNavigationOpen);
        };

        this.closeNavigation = () => {
            $body.removeClass(classes.mobileNavigationOpen);
        };

        /**
         *
         * MAKES THE MOBILE MENU TOGGLE
         **/
        this.toggleNavigation = function() {
            $childMenu.prev("a").addClass("has-children");

            $navigation.find('.has-children').on('click', function(e) {
                $(this).siblings("ul.main-navigation__item--sub-navigation").slideDown(200);
                $(this).toggleClass("open");
                if ($(this).is(".open")) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            });
        };
    }

    cw.mobileNavigation = new MobileNavigation();

    $(document).ready(() => {
        cw.mobileNavigation.init();
    });

})(jQuery);






